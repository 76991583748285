<template>
  <div class="examRoom">
    <!-- 盒子 -->
    <!-- <img src="@/assets/img/question/bg.png"  /> -->
    <div class="top-box">
      <div class="title">独享虚拟考场</div>
      <div class="tips">为您量身打造的在线考场</div>

      <kind-choose-view :showKc="false" :type="117" @isOk="isOk" />
    </div>

    <!-- 内容 -->
    <div class="room-box" v-if="list && list.length > 0">
      <div class="room-list" v-for="(item, indc) in list" :key="indc">
        <div class="item-img">{{ item.name }}</div>
        <div class="item-info">
          <div class="item">
            <div class="item-title">状态：{{ roomStatu[item.roomStatus] }}</div>
            <div v-if="item.showStatus == 1">已拥有</div>
            <div v-else class="item-price">¥ {{ item.tenantPrice }}</div>
          </div>
          <div class="item">
            <div class="item-title">模式：{{ roomType[item.type] }}</div>
          </div>
          <div class="time">
            <div class="item-time"><i class="iconfont icon-shijian" />开始时间：{{ item.startTime }}</div>
            <div class="item-time"><i class="iconfont icon-shijian" />结束时间：{{ item.endTime }}</div>
          </div>
          <el-button v-if="item.tenantPrice > 0 && item.showStatus == 2 && item.type == 1 && item.roomStatus != 3"
            type="danger" plain @click="goOrder(15, item)">
            立即购买
          </el-button>
          <el-button v-else-if="(((!item.tenantPrice || item.tenantPrice == 0) && item.type == 1) || item.showStatus == 1) &&
            (item.roomStatus == 2 || item.roomStatus == 3)
            " type="success" @click="allopenclas(item)" plain>
            进入考场
          </el-button>
          <el-button v-else-if="item.roomStatus == 1 || item.type == 2" type="info" plain
            @click="expired(item)">进入考场</el-button>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 32]"
      :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange"
      background layout="total, sizes, prev, pager, next">
    </el-pagination>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import KindChooseView from "@/components/kindChooseView.vue";
import { getInfo, getToken } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
import { roomType, roomStatu } from "@/api/emun";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
export default {
  components: { KindChooseView },
  data() {
    return {
      userInfo: null,
      roomType: roomType,
      roomStatu: roomStatu,
      search: {},
      list: [],
      pageSize: 8,
      pageNum: 1,
      total: 0,
    };
  },
  created() {
    this.userInfo = getInfo();
    // 调用轮播图导航接口方法
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      answerClient
        .getTenantPcRoomList(
          this.pageSize,
          this.pageNum,
          this.search.kind ? this.search.kind : undefined,
          this.search.zyId ? this.search.zyId : undefined,
          this.search.type ? this.search.type : undefined,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.total = res.total;
          this.list = res.rows;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {
      this.search = val;
      this.getList();
    },
    // 详情
    allopenclas(item) {
      this.$router.push(`/quesTion/examRoom/examRoomDetail?roomId=${item.id}`);
    },
    // 立即购买
    goOrder(type, item) {
      let token = getToken();
      if (token) {
        orderClient
          .addOrders(item.id, localStorage.getItem("userId"), item.name, type, item.name, item.kind)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    expired(item) {

      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top-box {
  background-image: url("../../../assets/img/question/bg.png");
  background-size: 100% 100%;
  height: 500px;
  width: 100%;
  text-align: center;

  .title {
    font-size: 44px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 44px;
    color: #ffffff;

    padding-top: 68px;
  }

  .tips {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;

    margin-top: 24px;
  }

  .zychoice {
    margin-top: 55px;
  }
}

.examRoom {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .room-box {
    width: calc(1200px + 42px);
    margin: 30px auto 40px;
    padding-left: 21px;
    background: #f5f7fa;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .room-list {
      width: 284px;
      margin-right: 21px;
      margin-bottom: 40px;

      .item-img {
        width: 100%;
        height: 160px;
        display: block;
        background-image: url("../../../assets/img/question/icon.png");
        background-size: 100% 100%;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 32px;
        color: #ffffff;

        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 26px;
        // text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.979);
      }

      .item-info {
        width: 100%;
        padding: 0px 16px 20px;
        position: relative;
        background: #ffffff;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 14px;
        color: #666666;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
          margin-top: 12px;

          .item-price {
            font-size: 18px;
            font-family: MicrosoftYaHei;
            line-height: 18px;
            color: #ff4027;
          }
        }

        .time {
          width: 253px;
          height: 67px;
          background: #f5f7fa;

          text-align: center;
          margin-top: 16px;
          padding: 1px 0px;
          line-height: 32px;

          .iconfont {
            font-size: 14px;
            color: #666666;
            margin-right: 8px;
          }
        }

        /deep/.el-button {
          width: 253px;
          height: 40px;

          border-radius: 0px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;

          margin-top: 12px;
        }
      }
    }
  }
}

.isNull {
  width: 1200px;
  margin: auto;
  margin-top: 40px;
}
</style>
